import React from 'react';
import classes from "../../AboutYouSummary/AboutYouSummaryComp/AboutYouSummaryComp.module.scss";
import { Col, Grid, Row } from "react-flexbox-grid";
import Navigation from "../../Navigation/Navigation";
import { connect } from "react-redux";
import AboutYouSumSave from "../../AboutYouSummary/AboutYouSumSave";
import AboutYouSumHeader from "../../AboutYouSummary/AboutYouSumHeader";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const InvestorProfileSummaryComp = ( props ) => {

    const data = [
        {
            title: 'Profile',
            inputNameYou: 'profileYou',
            inputNameSpouse: 'profileSpouse',
            you: props.profileYou,
            spouse: props.profileSpouse
        },
        {
            title: 'Withstand short-term losses?',
            inputNameYou: 'shortTermLossesYou',
            inputNameSpouse: 'shortTermLossesSpouse',
            you: props.shortTermLossesYou,
            spouse: props.shortTermLossesSpouse
        },
        {
            title: 'Tolerate periods of volatility?',
            inputNameYou: 'longTermReturnsYou',
            inputNameSpouse: 'longTermReturnsSpouse',
            you: props.longTermReturnsYou,
            spouse: props.longTermReturnsSpouse
        },
        {
            title: 'Anticipated recovery period (years)',
            inputNameYou: 'recoveryPeriodYou',
            inputNameSpouse: 'recoveryPeriodSpouse',
            you: formatYears(props.longTermReturnsYou, props.recoveryPeriodYou),
            spouse: formatYears(props.longTermReturnsSpouse, props.recoveryPeriodSpouse)
        },
        {
            title: 'Guaranteed retirement income?',
            inputNameYou: 'guaranteedFeeYou',
            inputNameSpouse: 'guaranteedFeeSpouse',
            you: props.guaranteedFeeYou,
            spouse: props.guaranteedFeeSpouse
        },
        {
            title: 'Percentage income guaranteed',
            inputNameYou: 'guaranteedPercentageYou',
            inputNameSpouse: 'guaranteedPercentageSpouse',
            you: formatGuaranteedPercentage(props.guaranteedFeeYou, props.guaranteedPercentageYou),
            spouse: formatGuaranteedPercentage(props.guaranteedFeeSpouse, props.guaranteedPercentageSpouse)
        },
        {
            title: 'Rate of return',
            inputNameYou: 'rateOfReturnYou',
            inputNameSpouse: 'rateOfReturnSpouse',
            you: props.rateOfReturnYou+'%',
            spouse: props.rateOfReturnSpouse+'%'
        }
    ];

    function formatYears(val,dependantVal) {
        if(val === 'Yes'){
            switch (dependantVal) {
                case 1:
                    return '0-1 years';
                case 3:
                    return '1-3 years';
                case 5:
                    return '3-5 years';
                default:
                    return '0';
            }
        }
        return '0';
    }

    function formatGuaranteedPercentage(val,dependantVal) {
        if(val === 'Yes') {
            return dependantVal+'%';
        }
        return '0';
    }

    const additionalClass = props.isSpouse ? 3 : 6;

    const elements = data.map((val, index) => {
        let flag =true;

        switch (val.inputNameYou) {
            case "recoveryPeriodYou":
                if(props.longTermReturnsYou === 'No' && props.longTermReturnsSpouse === 'No')
                flag = false;
                break;
            case "guaranteedPercentageYou":
                if(props.guaranteedFeeYou === 'No' && props.guaranteedFeeSpouse === 'No')
                flag = false;
                break;
            default:
                flag = true
        }

        if (flag) {
            return (
                <Row
                    key={index}
                    className={classes.Row}
                >
                <Col xs={12} sm={6}>
                    <strong>{val.title}</strong>
                </Col>
                <Col xs={12} sm={additionalClass}>
                    <input
                        type = "text"
                        name={val.inputNameYou}
                        value={val.you || ''}
                        disabled={true}
                    />
                </Col>
                    {
                        props.isSpouse &&
                        <Col xs={12} sm={3}>
                        <input type = "text"
                               name={val.inputNameSpouse}
                               value={val.spouse || ''}
                               disabled={true}
                        />
                        </Col>
                    }

                    <Col xs={12} className={classes.Separator}><hr/></Col>
            </Row>
            )
        }

        return '';

    });

    return (
        <React.Fragment>
            <form className={classes.AboutYouSummary}>
                <Grid>
                    <AboutYouSumHeader/>

                    {elements}

                    <AboutYouSumSave/>
                </Grid>
            </form>

            <Navigation
                isCompleted={true}
                next={retirementCalcLinks.savingsAndInvestments}
            />
        </React.Fragment>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        shortTermLossesYou: state.investorProfile.shortTermLossesYou,
        shortTermLossesSpouse: state.investorProfile.shortTermLossesSpouse,
        longTermReturnsYou: state.investorProfile.longTermReturnsYou,
        longTermReturnsSpouse: state.investorProfile.longTermReturnsSpouse,
        recoveryPeriodYou: state.investorProfile.recoveryPeriodYou,
        recoveryPeriodSpouse: state.investorProfile.recoveryPeriodSpouse,
        guaranteedFeeYou: state.investorProfile.guaranteedFeeYou,
        guaranteedFeeSpouse: state.investorProfile.guaranteedFeeSpouse,
        guaranteedPercentageYou: state.investorProfile.guaranteedPercentageYou,
        guaranteedPercentageSpouse: state.investorProfile.guaranteedPercentageSpouse,
        rateOfReturnYou: state.investorProfile.rateOfReturnYou,
        rateOfReturnSpouse: state.investorProfile.rateOfReturnSpouse,
        profileYou: state.investorProfile.profileYou,
        profileSpouse: state.investorProfile.profileSpouse,
    }
}

export default connect(mapStateToProps, null)(InvestorProfileSummaryComp);