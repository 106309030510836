import React, { useEffect } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InvestorProfileSummaryComp from "../../../components/InvestorProfileSummary/InvestorProfileSummaryComp/InvestorProfileSummaryComp";
import { connect } from "react-redux";
import { secondStepCompleted } from "../../../store/actions/investorProfileActions";

const InvestorProfileSummary = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE SUMMARY',
    };

    useEffect(() => {
        props.secondStepCompleted(true)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(9/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{titles.layoutTitle}</title>
            </Helmet>

            <InvestorProfileSummaryComp/>

        </Layout>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(null, mapDispatchToProps)(InvestorProfileSummary);